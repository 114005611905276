import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Error404Sign from '@assets/error-404.svg';
import HafhLogo from '@assets/hafh-logo.svg';
import Button from '@atoms/Button';
import { TypoMBold } from '@atoms/Typos';
import Head from '@components/Head';
import routes from '@utils/routes';
import styles from './css';

const Error404 = (): JSX.Element => {
  const { t } = useTranslation('error');
  const router = useRouter();

  return (
    <div className="wrap">
      <Head title={t('404.pageTitle')} titleWithAppend={true} type="website" />
      <HafhLogo className="hafh-logo" />

      <Error404Sign className="hero" />
      <TypoMBold>{t('404.subtitle')}</TypoMBold>
      <div className="description">{t('404.description')}</div>
      <Button
        customClass="return-button"
        label={t('returnButton')}
        size="large"
        onClick={() => router.push(routes.root)}
      />
      <div className="padding" />

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Error404;
