import NextHead from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { isEmpty } from '@utils/utils';

// It's a bit different texts from ALL_LANGUAGES because of marketing wise
const HREF_LANG_TITLE = {
  en: 'English (US)',
  ja: '日本語',
  ko: '한국어',
  'zh-tw': '繁體中文',
};

const Head = ({
  description = '',
  image = '',
  jsonLd = {},
  maxImagePreview = '',
  path = '',
  robots = '',
  title = '',
  titleWithAppend = false,
  type,
}) => {
  const { asPath, defaultLocale, locale, locales } = useRouter();
  const calculatedPath = path || (asPath === '/' ? '' : asPath.split('?')[0]);
  const { t } = useTranslation('common');

  const metaTitle = titleWithAppend
    ? `${title}${t('meta.append')}`
    : title || t('meta.title');
  const metaDescription = description
    ? description.replaceAll(/<[^<>]+>/g, '')
    : t('meta.description');
  const urlWithLocale = `${process.env.NEXT_PUBLIC_BASE_URL}${
    locale === defaultLocale ? '' : `/${locale}`
  }${calculatedPath}`;

  return (
    <NextHead>
      <title>{metaTitle}</title>
      <meta content={metaDescription} name="description" />
      <meta content={type} property="og:type" />
      <meta content="HafH" property="og:site_name" />
      <meta content={metaTitle} property="og:title" />
      <meta content={metaDescription} property="og:description" />
      <meta
        content={
          image || `${process.env.NEXT_PUBLIC_GCS_URL}/ogp/common-${locale}.png`
        }
        property="og:image"
      />
      <meta content={metaTitle} property="og:image:alt" />
      <meta content={urlWithLocale} property="og:url" />
      {process.env.NEXT_PUBLIC_CLUSTER_ENV === 'production' && robots && (
        <meta content={robots} name="robots" />
      )}
      {maxImagePreview && <meta content={maxImagePreview} name="robots" />}
      <meta content="594049534670470" property="fb:app_id" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="@HafHtheWorld" name="twitter:site" />
      <link href={urlWithLocale} rel="canonical" />
      {locales.map((l) => (
        <link
          href={`${process.env.NEXT_PUBLIC_BASE_URL}/${l}${calculatedPath}`}
          hrefLang={l}
          key={l}
          rel="alternate"
          title={HREF_LANG_TITLE[l]}
        />
      ))}
      <link
        href={`${process.env.NEXT_PUBLIC_BASE_URL}${calculatedPath}`}
        hrefLang="x-default"
        rel="alternate"
      />
      {!isEmpty(jsonLd) && (
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd),
          }}
          type="application/ld+json"
        />
      )}
    </NextHead>
  );
};

export default Head;
